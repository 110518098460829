var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amount-buttons-wrapper"},[_c('label',[_vm._v(_vm._s(_vm.$t('deposit.amount')))]),_c('div',{staticClass:"amount-values-wrapper"},[_vm._l((_vm.buttonsValue),function(buttonValue,index){return _c('button',{key:index,staticClass:"button amount-value",class:{
				active: _vm.formAmountValue === buttonValue && !_vm.customInputEnabled,
			},attrs:{"type":"button"},on:{"click":function($event){return _vm.presetAmountSelected(buttonValue)}}},[_vm._v(" "+_vm._s(Intl.NumberFormat(_vm.$i18n.locale, { style: 'currency', currency: _vm.currency }).format(buttonValue))+" ")])}),_c('button',{staticClass:"button amount-value",class:{
				active: _vm.customInputEnabled,
			},attrs:{"type":"button"},on:{"click":_vm.toggleCustomInputVisibility}},[_vm._v(" "+_vm._s(_vm.$t('withdrawal.custom-amount'))+" ")])],2),_c('div',{staticClass:"custom-amount",class:{
			'custom-amount--hidden': !_vm.customInputEnabled,
		}},[_c('div',{staticClass:"custom-amount__holder"},[_c('div',{staticClass:"confine"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('global.min')))]),_c('span',{staticClass:"value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.getMinAmount))])])]),_c('div',{staticClass:"input"},[_c('fieldset',{staticClass:"form__fieldset"},[_c('input',{staticClass:"form__textfield form__textfield--rounded_white",class:{ error: !_vm.amountValidation && _vm.inputValue },attrs:{"id":"amountInput","type":"number","name":"customAmount","placeholder":[[_vm.$t('withdrawal.custom-amount')]]},on:{"keyup":_vm.validateAmount,"focus":_vm.onCustomInputFocused,"blur":_vm.onCustomInputBlur}}),_c('div',{staticClass:"input-group-append input-group-append--white"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"currency-addon"}},[_vm._v(_vm._s(_vm.currency))])])])]),_c('div',{staticClass:"confine"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('global.max')))]),_c('span',{staticClass:"value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.currentMethod ? (+_vm.currentMethod.max ? _vm.currentMethod.max + ' ' + _vm.currency : '∞') : ''))])])]),_c('div',{staticClass:"mobile-info"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('global.min'))+" "+_vm._s(_vm.getMinAmount)+"/ "+_vm._s(_vm.$t('global.max'))+" "+_vm._s(_vm.currentMethod ? (+_vm.currentMethod.max ? _vm.currentMethod.max + ' ' + _vm.currency : '∞') : '')+" ")])])]),_vm._m(0)]),_c('FormError',{attrs:{"error":_vm.formError}}),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-amount__validation-msg"},[_c('fieldset',{staticClass:"form__fieldset form__fieldset--rounded_black"},[_c('label',{staticClass:"error",attrs:{"for":"customAmount"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-separator-wrapper"},[_c('div',{staticClass:"line-separator"})])
}]

export { render, staticRenderFns }