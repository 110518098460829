<template>
	<div class="amount-buttons-wrapper">
		<label>{{ $t('deposit.amount') }}</label>
		<div class="amount-values-wrapper">
			<button
				v-for="(buttonValue, index) in buttonsValue"
				:key="index"
				type="button"
				class="button amount-value"
				:class="{
					active: formAmountValue === buttonValue && !customInputEnabled,
				}"
				@click="presetAmountSelected(buttonValue)"
			>
				{{ Intl.NumberFormat($i18n.locale, { style: 'currency', currency: currency }).format(buttonValue) }}
			</button>
			<button
				type="button"
				class="button amount-value"
				:class="{
					active: customInputEnabled,
				}"
				@click="toggleCustomInputVisibility"
			>
				{{ $t('withdrawal.custom-amount') }}
			</button>
		</div>
		<div
			class="custom-amount"
			:class="{
				'custom-amount--hidden': !customInputEnabled,
			}"
		>
			<div class="custom-amount__holder">
				<div class="confine">
					<span class="label">{{ $t('global.min') }}</span>
					<span class="value"
						><strong> {{ getMinAmount }}</strong></span
					>
				</div>
				<div class="input">
					<fieldset class="form__fieldset">
						<input
							id="amountInput"
							type="number"
							name="customAmount"
							class="form__textfield form__textfield--rounded_white"
							:placeholder="[[$t('withdrawal.custom-amount')]]"
							:class="{ error: !amountValidation && inputValue }"
							@keyup="validateAmount"
							@focus="onCustomInputFocused"
							@blur="onCustomInputBlur"
						/>
						<div class="input-group-append input-group-append--white">
							<span id="currency-addon" class="input-group-text">{{ currency }}</span>
						</div>
					</fieldset>
				</div>
				<div class="confine">
					<span class="label"> {{ $t('global.max') }}</span>
					<span class="value"
						><strong>
							{{ currentMethod ? (+currentMethod.max ? currentMethod.max + ' ' + currency : '∞') : '' }}</strong
						></span
					>
				</div>
				<div class="mobile-info">
					<p>
						{{ $t('global.min') }}
						{{ getMinAmount }}/
						{{ $t('global.max') }}
						{{ currentMethod ? (+currentMethod.max ? currentMethod.max + ' ' + currency : '∞') : '' }}
					</p>
				</div>
			</div>
			<div class="custom-amount__validation-msg">
				<fieldset class="form__fieldset form__fieldset--rounded_black">
					<label class="error" for="customAmount" />
				</fieldset>
			</div>
		</div>

		<FormError :error="formError" />

		<div class="line-separator-wrapper">
			<div class="line-separator" />
		</div>
	</div>
</template>

<script>
const FormError = () => import('../forms/FormError.vue');

export default {
	name: 'AmountButtons',
	components: {
		FormError,
	},
	props: {
		buttonsValue: Array,
		formAmountValue: Number,
		currency: String,
		formError: String,
		currentMethod: Object,
		overriddenMinAmount: Number,
	},
	data() {
		return {
			inputValue: null,
			amountValidation: false,
			isCustomInputFocused: false,
			customInputEnabled: false,
		};
	},
	computed: {
		getMinAmount() {
			if (this.overriddenMinAmount) {
				return Intl.NumberFormat(this.$i18n.locale, { style: 'currency', currency: this.currency }).format(
					this.overriddenMinAmount
				);
			}

			if (this.currentMethod) {
				if (this.currentMethod.min) {
					return Intl.NumberFormat(this.$i18n.locale, { style: 'currency', currency: this.currency }).format(
						this.currentMethod.min
					);
				}
				return Intl.NumberFormat(this.$i18n.locale, { style: 'currency', currency: this.currency }).format(0);
			}
			return '';
		},
	},
	mounted() {
		this.presetAmountSelected(this.formAmountValue);
	},
	methods: {
		presetAmountSelected(amountValue) {
			document.getElementById('amountInput').value = '';
			this.customInputEnabled = false;
			this.amountValidation = true;
			this.inputValue = null;
			this.$emit('set-button-value', amountValue);
			this.$emit('input-amount-value-validation', this.amountValidation);
		},
		validateAmount(event) {
			const minAmount = this.currentMethod?.min || 0;
			const maxAmount = this.currentMethod?.max || Number.POSITIVE_INFINITY;
			const value = Number(event.target.value);
			this.inputValue = value;
			this.$emit('set-button-value', value);
			if (value >= minAmount && value <= maxAmount) {
				this.amountValidation = true;
			} else {
				this.amountValidation = false;
			}
			this.$emit('input-amount-value-validation', this.amountValidation);
		},
		toggleCustomInputVisibility() {
			this.customInputEnabled = !this.customInputEnabled;
		},
		onCustomInputFocused() {
			this.isCustomInputFocused = true;
		},
		onCustomInputBlur() {
			this.isCustomInputFocused = false;
		},
	},
};
</script>
<style lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';
@import 'src/assets/style/forms';

//  custom amount module
$m: custom-amount;

.#{$m} {
	transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
	max-height: 150px;
	opacity: 1;
	margin-bottom: 0.5rem;

	&--hidden {
		opacity: 0;
		max-height: 0;
		overflow: hidden;
	}

	&__separator {
		display: flex;
		align-items: center;
		text-align: center;
		font-size: 12px;
		padding: 30px 0;

		&:before,
		&:after {
			content: '';
			flex: 1;
			border-bottom: 1px solid $shark-black;
		}

		&:before {
			margin-right: 20px;
		}

		&:after {
			margin-left: 20px;
		}
	}

	&__holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 3rem;

		@include min-screen($md + 1) {
			flex-direction: row;
		}

		@include max-screen($md) {
			padding-top: 2rem;
		}

		.mobile-info {
			order: 2;
			@include min-screen($md + 1) {
				display: none;
			}

			p {
				font-size: 11px;
				line-height: 18px;
				color: $regent-gray;
				margin: 0;
			}
		}

		.confine {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex: 0 1 17%;

			@include max-screen($md) {
				order: 2;
				display: none;
			}

			.label,
			.value {
				font-size: 11px;
				line-height: 18px;
				color: $regent-gray;

				strong {
					font-weight: $bold;
				}
			}
		}

		.input {
			flex: 2;

			@include max-screen($md) {
				order: 1;
				flex: 0 0 100%;
				width: 100%;
			}

			fieldset {
				display: flex;

				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				@include min-screen($md + 1) {
					margin: 0;
				}

				input {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-right: none;

					&:focus,
					&:active {
						border-right: initial;
					}

					&:focus + .input-group-append span {
						border-top: 1px solid $white;
						border-right: 1px solid $white;
						border-bottom: 1px solid $white;
					}

					//if error

					&.error {
						border-top: 1px solid $error-color;
						border-right: 1px solid $error-color;
						border-bottom: 1px solid $error-color;

						&:focus + .input-group-append span {
							border-top: 1px solid $white;
							border-right: 1px solid $white;
							border-bottom: 1px solid $white;
						}

						&:focus {
							border-top: 1px solid $white;
							border-right: 1px solid $white;
							border-bottom: 1px solid $white;
						}
					}

					&.error + .input-group-append span {
						border-top: 1px solid $error-color;
						border-right: 1px solid $error-color;
						border-bottom: 1px solid $error-color;
					}
				}

				.input-group-append {
					display: flex;
					justify-self: end;

					span {
						display: flex;
						align-items: center;
						padding: 15px;
						margin-bottom: 0;
						font-size: rem(14px);
						font-weight: $normal;
						@include line-height(14);
						height: 48px;
						color: $regent-gray;
						text-align: center;
						white-space: nowrap;
						background-color: $bunker-black;
						border-top: 1px solid rgba(255, 255, 255, 0.1);
						border-right: 1px solid rgba(255, 255, 255, 0.1);
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border-radius: 0 3px 3px 0;
						@include transition(0.3s);
					}

					&--white {
						span {
							background: $input-gray;
							color: $regent-gray;
							font-weight: $bold;
							font-size: $label;
						}
					}
				}
			}
		}
	}

	&__validation-msg {
		fieldset {
			margin: 0 auto;

			.error {
				left: 0px;
			}
		}
	}
}

.amount-buttons-wrapper {
	box-sizing: border-box;
	margin: 0 auto;

	label {
		font-size: 12px;
		line-height: 60px;
		color: $santas-gray;
		margin-bottom: 0px;
		text-align: center;
	}

	.amount-values-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 15px;
		@include max-screen($md) {
			flex-direction: column;
		}

		button.amount-value {
			flex: 1;

			.small-width {
				width: 100% !important;
			}

			margin-right: 10px;
			min-height: 48px;
			@include max-screen($md) {
				width: 100%;
				margin: 0 auto 10px auto;
				max-width: 100%;
			}

			&:last-of-type {
				margin-bottom: 0px;
			}
		}
	}

	button {
		@include max-screen(450px) {
			width: calc(100% - 40px);
			margin: 10px auto 0;
		}
	}
}
</style>
